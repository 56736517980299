import React, { useState, useEffect, useContext } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ActiveUserContext } from "../../gatsby-browser";
import Vimeo from "@u-wave/react-vimeo";
import PlayerNavigation from "./playerNavigation";
import screenfull from "screenfull";
const Clickablefullscreenvideo = ({
  vimeoid,
  title,
  subtitle,
  p,
  bg = false,
}) => {
  const { active, setActive } = useContext(ActiveUserContext);
  const [playing, setPlaying] = useState(false);
  const [slide, setSlide] = useState(0);
  const [time, setTime] = useState(0);
  const [hiddenNavigation, setHiddenNavigation] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [videoLoading, setVideLoading] = useState(true);
  const [videoActive, setVideoActive] = useState(false);
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(0);
  const [player, setPlayer] = useState({});
  const [videoDuration, setVideoDuration] = useState(0);
  const [interaction, setInteraction] = useState(false);
  // const handle = useFullScreenHandle();

  useEffect(() => {
    setVideoActive(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (playing) {
        setHiddenNavigation(true);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [playing, hiddenNavigation]);

  useEffect(() => {
    if (active) {
      // setMuted(false);
      // setVolume(1);
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      // setMuted(false);
      // setVolume(1);
    }
  }, []);

  return (
    <div className="hero-video" id="player">
      <div className="player__nav">
        {!hiddenNavigation ? (
          <>
            <div className="player__nav__upper"></div>
            <div className="player__nav__mid">
              {videoLoading ? (
                <></>
              ) : (
                <button
                  className={`state-${playing ? "pause" : "play"}`}
                  style={{
                    fontSize: "24px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  onClick={() => setPlaying(!playing)}
                ></button>
              )}
            </div>
            <PlayerNavigation
              setPlaying={setPlaying}
              playing={playing}
              setSlide={setSlide}
              slide={slide}
              videoDuration={videoDuration}
              player={player}
              setMuted={setMuted}
              muted={muted}
              setVolume={setVolume}
              volume={volume}
              fullscreen={fullscreen}
              setFullscreen={setFullscreen}
              hideNavFullscreen={false}
              hideNavVolume={false}
            />
          </>
        ) : (
          <div
            className="player__nav__hidden"
            onClick={() => {
              setHiddenNavigation(false);
            }}
            onMouseMove={() => {
              setHiddenNavigation(false);
            }}
          />
        )}
      </div>

      {videoActive && (
        <Vimeo
          controls={false}
          paused={!playing}
          showByline={false}
          autopause={false}
          start={time}
          loop={true}
          video={vimeoid}
          className="video"
          volume={volume}
          responsive={true}
          muted={muted}
          onLoaded={(player) => {
            setVideLoading(false);
            setPlaying(true);
          }}
          onReady={(playerx) => {
            playerx.getDuration().then((item) => {
              setVideoDuration(item);
            });
            setPlayer(playerx);
          }}
          onTimeUpdate={(e) => {
            setSlide(e.percent * 100);
          }}
        />
      )}
    </div>
  );
};
export default Clickablefullscreenvideo;
