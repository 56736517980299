import React from "react";
import Cover from "../images/_cover.png";
import "../styles/main.scss";
import "../styles/typography.scss";
import "../styles/design.scss";
import { Parallax } from "react-scroll-parallax";
import { motion } from "framer-motion";
const Design = ({ speed }) => {
  return (
    <>
      <div className="design__wrapper">
        <img className="design__wrapper-img" src={Cover} />
        <div className="design__left">
          <Parallax speed={speed}>
            <motion.div>
              <h2 className="desgin__left-title">we are </h2>
            </motion.div>
          </Parallax>
        </div>
      </div>
    </>
  );
};

export default Design;
