import React, { useEffect, useState } from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import line from "../images/Rectangle 186.svg";
import logo from "../images/image-1.png";
import "../styles/main.scss";
import "../styles/index.scss";
import "../styles/weareg58.scss";
import heroImage from "../images/we-are-g58-image.jpg";
import heroText from "../images/we-are-g58-text.svg";
import Clickablefullscreenvideo from "../components/clickablefullscreenvideo";
import Design from "../components/design.js";

import { Helmet } from "react-helmet";
const WeareG58 = () => {
  const [paralaxSpeed, setParalaxSpeed] = useState(1);
  const [counter, setCounter] = useState(0);
  const [videoActive, setVideoActive] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        // const menuElement = document.getElementsByClassName("navbar__wrapper__links");
        setParalaxSpeed(10);
      } else {
        setParalaxSpeed(2);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Helmet>
        <title>G58 - We are G58</title>
      </Helmet>
      <Layout>
        <Container className="weareg58">
          <Design speed={paralaxSpeed} />
          <div className="weareg58__section1"></div>
          <div className="weareg58__section2">
            <div className="weareg58__section2__wrapper">
              <Clickablefullscreenvideo vimeoid="https://vimeo.com/687150239/952885fb51" />
            </div>
          </div>
          <div className="weareg58__section3">
            <div className="weareg58__section3__wrapper">
              <p className="weareg58__section3__text--light">
                <b>G58</b> to przede wszystkim zespół nieprzypadkowych ludzi,
                którzy się rozumieją i od samego początku mają spójną wizję
                efektu końcowego każdej produkcji. To gwarantuje i definiuje
                wyraźny styl, który reprezentujemy, który bezpośrednio przekłada
                się na oryginalność wizualną oraz merytoryczną w realizowanych
                przez nas projektach.
              </p>
              <img src={line} className="weareg58__section3__line" />
              <p className="weareg58__section3__text--light">
                Nie chcemy być jak „każdy”.
                <br />
                Jesteśmy G58.
              </p>
            </div>
          </div>
          <div className="weareg58__section4">
            <h3>Let's talk</h3>
            <div className="weareg58__section4__contact">
              <a
                href="mailto:weare@g58.pl"
                className="weareg58__section4__contact-value"
              >
                weare@g58.pl
              </a>
              <a
                href="tel:+48604339933"
                className="weareg58__section4__contact-value"
              >
                +48 604 33 99 33
              </a>
              <p className="weareg58__section4__contact-value--address">
                G58 Sp. z o.o.
                <br />
                ul.Sienkiewicza 11/5, 80-227 Gdańsk
              </p>
            </div>
          </div>
          <div className="weareg58__section5">
            <img src={heroImage} />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default WeareG58;
